import React, {Component} from "react";
import Popper from '@material-ui/core/Popper';
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyIcon from '@material-ui/icons/CallSplitOutlined';
import axios from "axios";
import {Product} from "mesmetric-v2-common/models";
import Fade from "@material-ui/core/Fade";
import "./popper.scss"
import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import AbsoluteSpinner from "../../../../Components/UI/AbsoluteSpinner/AbsoluteSpinner";
import {AppState} from "../../../../Store";
import {searchProducts} from "../../../../ActionCreators/Products";
import {parseError} from "../../../../ActionCreators/Error";
import {getAxiosConfig} from "../../../../ActionCreators/User";
import { RouteComponentProps } from "react-router";
import _ from "lodash";

interface OwnProps {
    label?: string,
    productId: string,
    displayIcon?: boolean,
    customIcon?: JSX.Element
}

interface DispatchProps {
    searchProducts: any
}

type Props = OwnProps & DispatchProps & RouteComponentProps;

interface State {
    anchorEl: null | HTMLElement,
    id: string,
    productSending: boolean
}

class CloneProductPopper extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            anchorEl: null,
            id: "cloneProduct",
            productSending: false,
        }
    }

    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            anchorEl: this.state.anchorEl ? null : event.currentTarget
        });
    };

    putClonedProductToDatabase = (product?: Product) => {
        this.setState({
            productSending: true
        });

        axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/products', product, getAxiosConfig())
            .then((response) => {
                this.props.searchProducts();
                const newProductId = response.data._id;
                this.redirectToNewProduct(newProductId);
            })
            .catch(parseError)
            .finally(() => {
                this.setState({
                    anchorEl: null,
                    productSending: false
                });
            })


    };
    redirectToNewProduct = (productId: string) => {
        this.props.history.replace(`/products/edit/${productId}/0`);
    };

    cloneProduct = () => {
        axios.get<Product>(`${process.env.REACT_APP_DATA_ENDPOINT}/products/${this.props.productId}`, getAxiosConfig())
            .then(result => {
                let clonedProduct = result?.data;
                const randomId = _.random(1,1000);
                delete clonedProduct._id;
                clonedProduct = {
                    ...clonedProduct,
                    syncToPrestaShop: false,
                    name: clonedProduct.name + "-clone" + randomId,
                    code: clonedProduct.code + "-clone-" + randomId,
                    clonedProduct: true,
                }
                this.putClonedProductToDatabase(clonedProduct)
            })
            .catch(parseError);
    };

    onClosePopper = () => {
        this.setState({
            anchorEl: null
        })
    };

    render = (): JSX.Element => {
        return (
            <>
                <Tooltip title={this.props.label||"Clone"}>
                    <Button
                        variant="contained"
                        aria-describedby={this.state.id}
                        className={"clone-product"}
                        onClick={this.handleClick}
                    >
                        {this.props.customIcon ? this.props.customIcon :
                            this.props.displayIcon ? <FileCopyIcon/> : <span>Klonuj</span>}
                    </Button>
                </Tooltip>
                <Popper id={this.state.id}
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        placement={"bottom"}
                        transition>
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <div className="popper">
                                <span>Potwierdź aby sklonować produkt.</span>
                                <div className={"buttons"}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.cloneProduct}>
                                        <span>POTWIERDŹ</span>
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="secondary"
                                        onClick={this.onClosePopper}>
                                        <span>ANULUJ</span>
                                    </Button>
                                </div>
                                {this.state.productSending && <AbsoluteSpinner/>}
                            </div>
                        </Fade>
                    )}
                </Popper>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>) => ({
    searchProducts: () => dispatch(searchProducts())
});

export default connect(null, mapDispatchToProps)(CloneProductPopper);
