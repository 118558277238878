import React, {useEffect, useState} from "react";
import {Product} from "mesmetric-v2-common/models";
import {connect} from "react-redux";
import "./productView.scss";
import {RouteComponentProps, withRouter} from "react-router";
import {Tab, Tabs} from "@material-ui/core";
import ProductDetails from "./ProductDetails/ProductDetails";
import axios from "axios";
import _ from "lodash";
import Spinner from "./Common/Spinner/Spinner";
import {AppState} from "../../Store";
import {removeProductData, setProductData} from "../../ActionCreators/ProductData";
import RelatedProducts from "./RelatedProducts/RelatedProducts";
import DescriptionAndNotes from "./DescriptionAndNotes/DescriptionAndNotes";
import {Action} from "redux";
import {ThunkDispatch} from "redux-thunk";
import Filters from "./Filters/Filters";
import ProductTemplateAttributes from "./ProductTemplateAttributes/ProductTemplateAttributes";
import {getAxiosConfig} from "../../ActionCreators/User";
import {parseError} from "../../ActionCreators/Error";
import Shop from "./Shop/Shop";
import PriceHistory from "./PriceHistory/PriceHistory";

type DispatchProps = {
    setProductData: (product: Product) => void,
    removeProductData: () => void
}

type StateProps = {
    productId: string
};

type ProductViewProps = RouteComponentProps<{ id: string, tab: string }> & DispatchProps & StateProps;

const TABS: { key: number, name: string }[] = [
    {
        key: 0,
        name: "GŁÓWNE"
    },
    {
        key: 1,
        name: "ATRYBUTY SZABLONU"
    },
    {
        key: 2,
        name: "FILTRY"
    },
    {
        key: 3,
        name: "OPIS I NOTATKI"
    },
    {
        key: 4,
        name: "PRODUKTY POWIĄZANE"
    },
    {
        key: 5,
        name: "SKLEP"
    },
    {
        key: 6,
        name: "HISTORIA CENY"
    }
];

const ProductView: React.FC<ProductViewProps> = (
    {
        match,
        productId,
        setProductData,
        removeProductData,
        history
    }) => {
    const selectedTabKey = match.params.tab ? parseInt(match.params.tab) : 0;

    const getProduct = async (): Promise<Product> => {
        try {
            const response = await axios.get<Product>(`${process.env.REACT_APP_DATA_ENDPOINT}/products/${match.params.id}`, getAxiosConfig());
            if (response.data._id === undefined) {
                return Promise.reject();
            }
            return (_.omit(response.data, ["createdAt", "updatedAt", "__v"]) as Product);
        } catch (e) {
            parseError(e);
            throw new Error();
        }
    };

    const [productLoaded, setProductLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            setProductLoaded(false); 
            setProductData(await getProduct())
            setProductLoaded(true)
        })()
        return () => {
            removeProductData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.id, setProductData, removeProductData])

    const renderProduct = (): JSX.Element | null => {
        return <>
            <Tabs value={selectedTabKey}
                  onChange={(e, newTabKey) => {
                      history.replace({
                          ...history.location,
                          pathname: `/products/edit/${match.params.id}/${newTabKey}`
                      })
                  }}>
                {TABS.map(tab => <Tab key={tab.key} label={tab.name}/>)}
            </Tabs>
            <div className={"scrollable"}>
                <div className={"product-content"}>
                    <ProductDetails visible={selectedTabKey === 0} productId={productId}/>
                    <ProductTemplateAttributes visible={selectedTabKey === 1} path={"attributes"}/>
                    <Filters visible={selectedTabKey === 2} path={"filters"}/>
                    <DescriptionAndNotes visible={selectedTabKey === 3}/>
                    <RelatedProducts visible={selectedTabKey === 4} path={"related"}/>
                    <Shop visible={selectedTabKey === 5}/>
                    <PriceHistory visible={selectedTabKey ===6}/>
                </div>
            </div>
        </>;
    }

    return <div className={"product-view"}>
        {!productLoaded && <Spinner size={64}/>}
        {productLoaded && renderProduct()}
    </div>
}

const mapStateToProps = (state: AppState): StateProps => ({
    productId: state.ProductData.productData?._id || ""
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>): DispatchProps => ({
    setProductData: (product: Product) => dispatch(setProductData(product, undefined, true)),
    removeProductData: () => dispatch(removeProductData())
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductView));
