import React, {Component} from "react";
import CheckboxButton from "../../Common/CheckboxButton/CheckboxButton";
import "./controls.scss";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloneProductPopper from "./CloneProductPopper";
import DeleteItem from "../../../../Components/DeleteItem/DeleteItem";
import {ThunkDispatch} from "redux-thunk";
import {AppState} from "../../../../Store";
import {Action} from "redux";
import {searchProducts} from "../../../../ActionCreators/Products";
import {connect} from "react-redux";
import axios from "axios";
import {Product} from "mesmetric-v2-common/models";
import {getAxiosConfig} from "../../../../ActionCreators/User";
import {parseError} from "../../../../ActionCreators/Error";
import {withRouter, RouteComponentProps} from "react-router-dom";

interface ControlProps extends RouteComponentProps {
    productId: string
}

interface DispatchProps {
    searchProducts: any
}

class Controls extends Component<ControlProps & DispatchProps> {
    private deleteProduct = async () => {
        try {
            await axios.delete<Product>(`${process.env.REACT_APP_DATA_ENDPOINT}/products/${this.props.productId}`, getAxiosConfig());
            this.props.searchProducts();
            this.props.history.goBack();
        } catch (e) {
            parseError(e);
        }
    }

    public render = (): JSX.Element =>
        <div className={"controls"}>
            <CheckboxButton
                path={"isActive"}
                label={"Aktywny"}
                description={"Produkt jest publicznie widoczny w katalogu"}
            />
            <CheckboxButton
                path={"syncToPrestaShop"}
                label={"Synchronizowany"}
                description={"Produkt jest synchronizowany do PrestaShop"}
            />
            <CloneProductPopper
                productId={this.props.productId}
                customIcon={<FileCopyIcon/>}
                label={"Klonuj produkt"}
                history={this.props.history} 
                location={this.props.location}
                match={this.props.match}
            />
            <DeleteItem
                onConfirm={this.deleteProduct}
                label={"Usuń produkt"}
            />
        </div>
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, Action>) => ({
    searchProducts: () => dispatch(searchProducts())
});

export default withRouter(connect(null, mapDispatchToProps)(Controls));
