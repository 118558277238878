import React, {useState} from "react";
import AbsoluteSpinner from "../../../../Components/UI/AbsoluteSpinner/AbsoluteSpinner";
import {default as Dropzone} from "react-dropzone";
import axios from "axios";
import {getAxiosConfig} from "../../../../ActionCreators/User";
import {Photo} from "mesmetric-v2-common/models";
import {getObjectId} from "../../../../Common/Utility";
import {parseError} from "../../../../ActionCreators/Error";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../Store";
import {updateValue} from "../../../../ActionCreators/ProductData";
import {SHOP_PHOTOS_PATH} from "../Photos/Photos";

const pictureExtensions = ["jpg", "jpeg", "png", "tiff", "tif"];

const UploadPhotos: React.FC = () => {
    const photos = useSelector<AppState, Photo[]>(state => state.ProductData.productData?.shop?.photos || [])
    const dispatch = useDispatch();

    const [filesToBeUploaded, setFilesToBeUploaded] = useState<File[]>([]);
    const [filesNotUploaded, setFilesNotUploaded] = useState<{ file: File, reason: string }[]>([]);

    const uploadPhoto = async (file: File) => {
        const formData = new FormData();
        formData.append("image", file);
        try {
            const response = await axios.post(process.env.REACT_APP_DATA_ENDPOINT + '/brands/photos', formData, getAxiosConfig());
            const newPhoto: Photo = {
                _id: getObjectId(),
                src: response.data.src,
                crops: [],
                srcResolved: response.data.srcResolved,
                meta: {
                    isLegacy: false
                },
                isCover: false,
            }
            dispatch(updateValue(SHOP_PHOTOS_PATH, [...photos, newPhoto]));
            setFilesToBeUploaded(filesToBeUploaded.filter(up => up !== file))
        } catch (e) {
            parseError(e);
            setFilesNotUploaded(state => state.concat({file, reason: e.message}));
            setFilesToBeUploaded(state => state.filter(up => up !== file))
        }
    };

    const onDrop = async (acceptedFiles: Array<File>) => {
        setFilesToBeUploaded(prevState => [...prevState, ...acceptedFiles]);
        const isImage = (file: File) => pictureExtensions.some(extension => file.name.toLowerCase().endsWith(extension));
        const images = acceptedFiles.filter(isImage);
        images.forEach(uploadPhoto);
    };

    return <Dropzone multiple={true} onDrop={onDrop}>
        {({getRootProps, getInputProps, isDragActive}) => (<>
                <div {...getRootProps()} className={"dropzone-target"}>
                    {filesToBeUploaded.length > 0 &&
                    <AbsoluteSpinner
                        label={<>
                            {'Liczba zdjęć w kolejce oczekujących na wgranie: ' + filesToBeUploaded.length + '. Proszę czekać...'}
                        </>}/>}
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <div className={"drag-box active"}>Upuść tutaj ...</div> :
                            <div className={"drag-box"}>Przeciągnij tutaj zdjęcia lub kliknij aby otworzyć
                                okno wyboru</div>
                    }
                </div>
                {filesNotUploaded.length > 0 &&
                <>
                    Pliki, których nie udało się wgrać:
                    <div className={"row"}>
                        {filesNotUploaded.map(notUploaded =>
                            <div className={"col-8"} key={notUploaded.file.name}>
                                {notUploaded.file.name} - {notUploaded.reason}
                            </div>)}
                    </div>
                </>
                }
            </>
        )}
    </Dropzone>;
};

export default UploadPhotos;